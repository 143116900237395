import { LoginRequest } from '@/compiled_proto/com/celertech/baseserver/communication/login/UpstreamLoginProto';
import {
    Ping,
    Pong,
    ProtobufMessage
} from '@/compiled_proto/com/celertech/baseserver/communication/protobuf/NettyCommunication';
import { AssetType } from '@/compiled_proto/com/celertech/marketdata/api/enums/AssetTypeProto';
import { MarketDataBookType } from '@/compiled_proto/com/celertech/marketdata/api/enums/MarketDataBookTypeProto';
import { MarketDataEntryType } from '@/compiled_proto/com/celertech/marketdata/api/enums/MarketDataEntryTypeProto';
import { MarketDataQuoteType } from '@/compiled_proto/com/celertech/marketdata/api/enums/MarketDataQuoteTypeProto';
import { PriceType } from '@/compiled_proto/com/celertech/marketdata/api/enums/PriceTypeProto';
import { ProductType } from '@/compiled_proto/com/celertech/marketdata/api/enums/ProductTypeProto';
import {
    MarketDataFormattedPriceEvent,
    MarketDataPriceEvent,
    MarketDataServiceClient,
    MarketDataServiceDefinition
} from '@/compiled_proto/com/celertech/marketdata/api/notification/MarketDataServiceProto';
import { MarketDataFullSnapshotDownstreamEvent } from '@/compiled_proto/com/celertech/marketdata/api/price/DownstreamPriceProto';
import { OrderRoutingOrderEvent } from '@/compiled_proto/com/celertech/orderrouting/api/notification/OrderServiceProto';
import { pushNotifications } from '@/helpers/notificationsHelper';
import { BlotterItem } from '@/model/blotters';
import { addMarketFull } from '@/services/MarketService';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { User, selectCredentials } from '@/state/reducers/authSlice';
import { setBalanceStatus } from '@/state/reducers/balanceSlice';
import { clearOrderBook, updateOrderBook } from '@/state/reducers/celerMarketSlice';
import { pushPriceChange, resetChart } from '@/state/reducers/chartDataSlice';
import { addBidAsk, clearBidAsk, generatePair, selectActiveMarketPair } from '@/state/reducers/marketPairSlice';
import { RootState, store } from '@/state/store';
import { Metadata, createChannel, createClient } from 'nice-grpc-web';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { WebSocketHook } from 'react-use-websocket/dist/lib/types';
import { Logger } from '../logger';
import { callbackOnlyInMainPage } from '../middleware';
import { convertPriceEvent } from '../pricebook';

interface MarketData {
    fullSnapshot: MarketDataFullSnapshotDownstreamEvent;
    marketDataFormattedPriceEvent: MarketDataFormattedPriceEvent;
}

export interface MarketDataContext extends Partial<WebSocketHook> {}

export interface MarketDataProviderProps {
    children: React.ReactNode;
}

const celerTechURL = window.config.integration.celertech.authority;
const livePricesType = window.config.modules.chart?.livePricesType || 'bid';
const wsChannelUrl = window.config.integration.celertech.websocket;
const wsChannel = createChannel(wsChannelUrl);
const marketDataServiceClient: MarketDataServiceClient = createClient(MarketDataServiceDefinition, wsChannel);

function MarketDataPriceEventContent(e) {
    const { fullSnapshot: u, ...i } = e;
    return u
        ? {
              fullSnapshot: {
                  ...u,
                  assetType: AssetType[u.assetType],
                  marketDataBookType: MarketDataBookType[u.marketDataBookType],
                  priceType: PriceType[u.priceType],
                  productType: ProductType[u.productType],
                  marketDataPriceSnapshotLevel: u.marketDataPriceSnapshotLevel.map((s) => ({
                      ...s,
                      marketDataEntryType: MarketDataEntryType[s.marketDataEntryType],
                      marketDataQuoteType: MarketDataQuoteType[s.marketDataQuoteType]
                  }))
              },
              ...i
          }
        : {};
}
const br = (e, u, i) => {
    // if (e.messageType === "ORDER_FX") {
    //     const {fxOrderSnapshotDownstreamEvent: s} = e;
    //     z(s, u),
    //     (i === "lite" ? _r : $e).sendOrder({
    //         ...s,
    //         loadingOrdersOnLogin: !0
    //     })
    // }
    // if (e.messageType === "ORDER_FUTURE") {
    //     const {futureOrderSnapshotDownstreamEvent: s} = e;
    //     z(s, u),
    //     (i === "commodities" ? Xe : Ye).sendOrder({
    //         ...s,
    //         loadingOrdersOnLogin: !0
    //     })
    // }
    // if (e.messageType === "ORDER_FIXEDINCOME") {
    //     const {fixedIncomeOrderSnapshotDownstreamEvent: s} = e;
    //     z(s, u),
    //     Ge.sendOrder(s)
    // }
    // if (e.messageType === "ORDER_REJECT") {
    //     const s = gr(e.createOrderRequestRejectDownstreamEvent);
    //     s != null && s.rejectReason && u(G({
    //         bypass: !0,
    //         message: `Order rejected: ${s == null ? void 0 : s.rejectReason} `,
    //         type: "rejected"
    //     }))
    // }
};
const hr = (e) => (u) => e.includes(u);
const yr = hr(['ORDER_FX', 'ORDER_FUTURE', 'ORDER_FIXEDINCOME', 'ORDER_REJECT']);

// const subject$8 = new Subject
// const priceObservable = {
//   sendPrices: e => {
//       var n;
//       if (((n = e == null ? void 0 : e.fullSnapshot.marketDataPriceSnapshotLevel) == null ? void 0 : n.length) === 0)
//           return subject$8.next(null);
//       subject$8.next(e)
//   }
// }

function Er({ protobufClassName, protobufMessageContents }) {
    if (protobufClassName.includes('MarketDataPriceEvent')) {
        const i = MarketDataPriceEvent.decode(protobufMessageContents);
        return {
            messageContents: MarketDataPriceEventContent(i),
            messageType: 'MARKET_DATA_PRICE'
        };
    }
    if (protobufClassName.includes('OrderRoutingOrderEvent')) {
        const i = OrderRoutingOrderEvent.decode(protobufMessageContents),
            s = {
                messageContents: i,
                messageType: ''
            };
        return (
            i.fxOrderSnapshotDownstreamEvent && (s.messageType = 'ORDER_FX'),
            i.futureOrderSnapshotDownstreamEvent && (s.messageType = 'ORDER_FUTURE'),
            // i.fixedIncomeOrderSnapshotDownstreamEvent && (s.messageType = 'ORDER_FIXEDINCOME'),
            i.createOrderRequestRejectDownstreamEvent && (s.messageType = 'ORDER_REJECT'),
            s
        );
    }
    return protobufClassName.includes('$Ping')
        ? {
              messageContents: Ping.decode(protobufMessageContents),
              messageType: 'PING'
          }
        : protobufClassName.includes('$Heartbeat')
        ? {
              messageContents: {},
              messageType: 'HEARTBEAT'
          }
        : {};
}

let F: any = null;

let notificationBuffer: OrderRoutingOrderEvent[] = [];
let isBuffering = false;
const MAX_BATCH_SIZE = 50;

const MarketDataContext = createContext({} as MarketDataContext);
export const useMarketData = () => useContext(MarketDataContext);
export const MarketDataProvider = (props: MarketDataProviderProps) => {
    const dispatch = useAppDispatch();
    const credentials = useAppSelector(selectCredentials);
    const activePair = useAppSelector(selectActiveMarketPair);

    const didUnmount = useRef(false);

    const wsURL = useMemo(() => {
        if (credentials) return `wss://${celerTechURL}/stream`;
        else return null;
    }, [credentials]);

    const websocket = useWebSocket<MarketData>(wsURL, {
        reconnectAttempts: 5,
        retryOnError: false,
        // reconnectAttempts: 5,
        // reconnectInterval: 5e3,
        // share: !1,
        // shouldReconnect: () => !0
        shouldReconnect: (closeEvent) => {
            return didUnmount.current === false;
        },
        onOpen: () => {
            const c = getWebSocket() as any;
            c.binaryType = 'arraybuffer';
            const f = LoginRequest.encode({
                username: credentials?.authToken || '',
                clientRequestId: '',
                password: '',
                text: '',
                twoFactorToken: ''
            }).finish();
            const n = ProtobufMessage.encode({
                protobufClassName: 'com.celertech.baseserver.communication.login.UpstreamLoginProto$LoginRequest',
                protobufMessageContents: f,
                conflationKey: '',
                metadata: void 0,
                sequenceNumber: '0'
            }).finish();
            sendMessage(n);
        },

        onMessage: (c) => {
            const d = ProtobufMessage.decode(new Uint8Array(c.data) as any);
            if ('protobufClassName' in d && 'protobufMessageContents' in d) {
                const { protobufClassName, protobufMessageContents } = d;
                const { messageType, messageContents } = Er({ protobufClassName, protobufMessageContents });
                // if ("marketDataFormattedPriceEvent"in messageContents && "fullSnapshot"in messageContents ){
                //     fe.sendPrices({
                //         ...messageContents
                //     })
                // }
                if (messageType === 'MARKET_DATA_PRICE') {
                    callback(messageContents);
                } else if (
                    'fxOrderSnapshotDownstreamEvent' in messageContents &&
                    'futureOrderSnapshotDownstreamEvent' in messageContents &&
                    'createOrderRequestRejectDownstreamEvent' in messageContents &&
                    yr(messageType)
                ) {
                    // console.log({ messageType, messageContents });
                    const state: RootState = store.getState();
                    const previousEvents: BlotterItem[] = state.blotter.blotterOrders;

                    notificationBuffer.unshift(messageContents);
                    if (notificationBuffer.length >= MAX_BATCH_SIZE) {
                        pushNotifications({ latestEvents: notificationBuffer, previousEvents, state, dispatch });
                        notificationBuffer = [];
                    } else if (!isBuffering) {
                        isBuffering = true;
                        setTimeout(() => {
                            pushNotifications({ latestEvents: notificationBuffer, previousEvents, state, dispatch });
                            notificationBuffer = [];
                            isBuffering = false;
                        }, 1000);
                    }
                } else if (messageType === 'PING') {
                    const l = Date.now();
                    F || (F = l);
                    const r = Pong.encode({
                        lastClientPingIntervalInMillis: String(l - F),
                        pingMessage: messageContents
                    }).finish();
                    F = l;
                    const S = ProtobufMessage.encode({
                        protobufClassName:
                            'com.celertech.baseserver.communication.netty.protobuf.NettyCommunication$Pong',
                        protobufMessageContents: r,
                        conflationKey: '',
                        metadata: void 0,
                        sequenceNumber: '0'
                    }).finish();
                    sendMessage(S);
                } else if (messageType === 'HEARTBEAT') sendMessage(c.data);
                // else {
                //     console.log({
                //         messageType,
                //         messageContents,
                //         fxOrderSnapshotDownstreamEvent: 'fxOrderSnapshotDownstreamEvent' in messageContents,
                //         futureOrderSnapshotDownstreamEvent: 'futureOrderSnapshotDownstreamEvent' in messageContents,
                //         createOrderRequestRejectDownstreamEvent:
                //             'createOrderRequestRejectDownstreamEvent' in messageContents
                //     });
                // }
            }
        },
        onError: (e) => {
            Logger({
                title: `MarketDataProvider WebSocket Error ${e}.`,
                callback: () => {}
            });
        },
        onClose: (e) => {
            Logger({
                title: `MarketDataProvider WebSocket Closed ${e}.`,
                callback: () => {}
            });
        }
    });

    const { readyState, lastJsonMessage, getWebSocket, sendMessage } = websocket;

    const priceChangeFilter = useCallback((celerCode: string) => celerCode === activePair.celer, [activePair]);

    const callback = useCallback(
        async (marketSub) => {
            if (!marketSub.isHeartBeat && marketSub.fullSnapshot?.securityCode) {
                const market = generatePair(marketSub.fullSnapshot.securityCode);
                const converted = convertPriceEvent(marketSub);
                if (converted) {
                    const bestBid = converted.priceBook.find((pb) => pb.type === 'bids')?.price;
                    const bestAsk = converted.priceBook.find((pb) => pb.type === 'asks')?.price;
                    const bestMid = converted.priceBook.find((pb) => pb.type === 'midprice')?.price;

                    if (bestBid && bestAsk && bestMid) {
                        const timestamp = parseInt(converted.snapshotMillis);
                        if (priceChangeFilter(market.celer)) {
                            dispatch(
                                pushPriceChange({
                                    symbol: market.netdania,
                                    price: livePricesType === 'mid' ? bestMid : bestBid,
                                    timestamp
                                })
                            );
                        }
                        dispatch(
                            addBidAsk({
                                celerPair: market.celer,
                                bid: bestBid,
                                ask: bestAsk
                                // timestamp
                            })
                        );
                    }
                    dispatch(updateOrderBook({ priceEvent: converted }));
                } else {
                    dispatch(clearBidAsk({ celerPair: market.celer }));
                    dispatch(clearOrderBook(marketSub.fullSnapshot.securityCode));
                }
            }
        },
        [priceChangeFilter]
    );

    useEffect(() => {
        dispatch(setBalanceStatus(ReadyState[readyState] as keyof typeof ReadyState));
    }, [readyState]);

    useEffect(() => {
        // When switching between pairs, we need to degrade the current pair and upgrade the new pair
        if (credentials && activePair.celer) {
            callbackOnlyInMainPage(() => {
                // Dispath action to fetch data
                dispatch(resetChart());
                if (activePair.celer) addMarketFull(credentials, activePair.celer);
            });
        }
    }, [credentials, activePair]);

    useEffect(() => {
        if (credentials) setupMarketSubscriptionWS(credentials);
    }, [credentials]);

    const value = useMemo<MarketDataContext>(() => {
        return { readyState, lastJsonMessage, getWebSocket };
    }, [readyState, lastJsonMessage, getWebSocket]);

    useEffect(() => {
        return () => {
            didUnmount.current = true;
        };
    }, []);

    return <MarketDataContext.Provider value={value}>{props.children}</MarketDataContext.Provider>;
};

const setupMarketSubscriptionWS = async (credentials: User) => {
    await marketDataServiceClient.subscribeToPrices(
        {},
        { metadata: Metadata({ 'authorization-token': credentials.authToken }) }
    );
};

// let geee: any = null;
// websocket.addEventListener('message', (C) => {
//     const parsedMessage = JSON.parse(C == null ? void 0 : C.data);
//     if (parsedMessage != null && parsedMessage.marketDataFormattedPriceEvent) {
//         callback(parsedMessage);
//     }
//     if (parsedMessage.lastServerPingIntervalInMillis) {
//         const w = Date.now();
//         geee || (geee = w);
//         const k = JSON.stringify({
//             pingMessage: parsedMessage,
//             lastClientPingIntervalInMillis: String(w - geee)
//         });
//         (geee = w), websocket.send(k);
//     }
// });
